import React, { useEffect, useState, useRef, useCallback, useLayoutEffect, useMemo } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';

import { Canvas } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useLoader, useThree } from '@react-three/fiber';
import { CanvasTexture } from 'three';
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

// checkmark for order success. circle with checkmark inside
import { FaCircleCheck } from "react-icons/fa6"
//another redo icon
import { FaRedoAlt } from "react-icons/fa";

import { BrowserRouter as Router, Route, Link, Routes, useSearchParams} from "react-router-dom";

registerPlugin(FilePondPluginImageTransform);

const API_URL = process.env.NODE_ENV == "production" ? "https://api.petals.design" :
"https://api-dev.petals.design"

var preloadImagePromise = (url) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.crossOrigin = "Anonymous";
    // make sure all images are either all cross origin or all not. Otherwise, browser will fetch twice.
    img.src = url;
    img.onload = () => resolve(img);
    img.onerror = () => reject(url);
  });
}

var Input = props => {
  return <input className={`border-2 px-3 py-2 rounded-sm bg-gray-200 focus:border-black focus:outline-none ${props.extraclasses||""} ${props.disabled ? "text-gray-500 cursor-not-allowed" : ""}`} {...props} />
  // selected border should be black, unrounded
}
var Button = props => {
  // animate hover color change
  const internals = <>
    <div className="mt-[-3px]" role="status">
      <svg aria-hidden="true" className="inline w-4 h-4 mr-0 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  </>

  const disabledClasses = `bg-gray-400 text-gray-600 cursor-not-allowed`

  var out = <>
    <button className={`bg-black rounded-sm text-white px-3 py-1  transition-colors duration-200 ${props.extraclasses||""} ${props.disabled ? disabledClasses : "hover:bg-gray-700"} flex flex-row items-center justify-center gap-x-2`} {...props} >
      {props.loading ? <> {internals} </> : props.children} 
    </button>
  </>
  return out
}

var Slider = props => {
  const min = props.min
  const max = props.max
  const val = props.value

  const inputRef = useRef(null);
  useLayoutEffect(() => {
    // set linear gradient on slider runnable track
    var pct = (val - min) / (max - min) * 100;
    var grad = `linear-gradient(90deg, #000000 ${pct}%, #ddd ${pct}%)`;
    inputRef.current && (inputRef.current.style.background = grad);
  }, [props.value]);

  var out = <>
    <div className={`${props.extraclasses||""}`}>
      <input type="range" className={`mt-1 w-full accent-black appearance-none border-none bg-gray-100 h-1 rounded-md cursor-pointer`} {...props} ref={inputRef} />
      {/* <div className="flex justify-between mt-1 mx-0.5">
        <span className="text-xs text-gray-700">{min}</span>
        <span className="text-xs text-gray-700">{max}</span>
      </div> */}
    </div>
  </>

  return out
}


const Checkbox = props => {
  const { checked, onChange, label, extraclasses, ...otherProps } = props;

  return <>
    <div className="flex items-center">
        <input id="default-checkbox" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded  dark:bg-gray-700 dark:border-gray-600 accent-black" checked={checked} onChange={onChange}/>
        <label className="ml-2 text-sm font-medium text-gray-900 select-none">{label}</label>
    </div>
  </>
}

var CameraController = () => {
  const { camera, gl } = useThree();
  useEffect(
    () => {
      const controls = new OrbitControls(camera, gl.domElement);

      controls.minDistance = 0.5;
      controls.maxDistance = 2;
      return () => {
        controls.dispose();
      };
    },
    [camera, gl]
  );
  return null;
};

var ModelPreview = ({canvasRef, canvasStateNum}) => {
  const gltf = useLoader(GLTFLoader, 'hoodiefromvrm.glb');
  const { scene } = useThree();

  const offscreenCanvasRef = useRef(null);
  const textureRef = useRef(null);

  useEffect(() => {
    if (!canvasRef.current) {
      return
    }
    gltf.scene.traverse((child) => {
      if (child.isMesh) {
        const canvas = document.createElement('canvas');
        canvas.width = 4096;
        canvas.height = 4096;
        offscreenCanvasRef.current = canvas;
        const texture = new CanvasTexture(canvas);
        texture.flipY = false;
        texture.colorSpace = THREE.SRGBColorSpace;
        

        textureRef.current = texture;
        child.material = new THREE.MeshPhysicalMaterial({
          color: 0xffffff,
          roughness: 0.8,   // Hoodies are typically not very shiny
          metalness: 0.0,   // Very low metalness, as hoodies aren't metallic
          clearcoat: 0.0,   // No clear coat layer
          clearcoatRoughness: 0.0,
          reflectivity: 0.3, // Moderate reflectivity to capture ambient environment
          bumpScale: 0.01,
          map: texture,
        })
      }
    })
  }, [canvasRef]);

  useEffect(() => {
    if (!offscreenCanvasRef.current || !canvasRef.current || !textureRef.current) {
      return
    }
    const canvas = offscreenCanvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = 0.20 * canvas.width
    const height = width * (canvasRef.current.height / canvasRef.current.width)
    const marginX = (canvas.width - width) / 2
    const marginY = 0.59 * canvas.height
    // fill to a #ccc
    ctx.fillStyle =  "#ffffff";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(canvasRef.current, marginX, marginY, width, height);
    textureRef.current.needsUpdate = true;
  }, [canvasStateNum]);

  return <>
    <primitive object={gltf.scene} />
  </>
}

var PatternPreview = ({imageURL, sizeParam, spacingParam, staggered, hasFlips, canvasRefIn, centered, productType, outputScaleInFrame}) => {
  productType = productType || "socks"
  const canvasRef = useRef(null);
  useEffect(() => {
    canvasRefIn.current = canvasRef.current
  }, [canvasRef.current])
  const devicePixelRatio = window.devicePixelRatio || 1;
  const [canvasStateNum, setCanvasStateNum] = useState(0);

  var replaceBackground = (canvas, targetColor, replaceColor, tolerance) => {
    var ctx = canvas.getContext("2d");
    var imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    var data = imgData.data;
    for (var i = 0; i < data.length; i += 4) {
      const isMatch = [0,1,2].every(colorIdx => Math.abs(data[i+colorIdx] - targetColor[colorIdx]) < 255*tolerance)
      if (isMatch) {
        data[i] = replaceColor[0]
        data[i+1] = replaceColor[1]
        data[i+2] = replaceColor[2]
        data[i+3] = replaceColor[3]
      }
    }
    ctx.putImageData(imgData, 0, 0);
  }

  var [processedImageURL, setProcessedImageURL] = useState(null);
  useEffect(() => {
    // create a new canvas with same dims as the image
    var canvas = document.createElement("canvas");
    canvas.width = 1024
    canvas.height = 1024
    var ctx = canvas.getContext("2d");
    var img = new Image();
    img.src = imageURL;
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      // console.log("replacing background");
      // console.log("img size is", img.width, img.height);
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      if (productType == "hoodie") {
        var imgBgColor = ctx.getImageData(0, 0, 1, 1).data
        replaceBackground(canvas, imgBgColor, [255, 255, 255, 0], 0.07)
      }
      const outWidth = canvas.width*outputScaleInFrame
      const outHeight = canvas.height*outputScaleInFrame
      var imageDataOut = ctx.getImageData(
        canvas.width *  (1-outputScaleInFrame)/2, 
        canvas.height * (1-outputScaleInFrame)/2, 
        outWidth, outHeight);
      // put that into processedImageURL in a blob
      var canvasOut = document.createElement("canvas");
      canvasOut.width = outWidth
      canvasOut.height = outHeight
      var ctxOut = canvasOut.getContext("2d");
      ctxOut.putImageData(imageDataOut, 0, 0);
      canvasOut.toBlob((blob) => {
        var url = URL.createObjectURL(blob);
        console.log(url)
        setProcessedImageURL(url);
      })
    }
  }, [imageURL])

  useEffect(() => {
    var canvas = canvasRef.current;
    var ctx = canvas.getContext("2d");
    var img = new Image();
    img.src = processedImageURL;

    img.crossOrigin = "Anonymous";
    var s = 2.2*1/16 * (10*sizeParam/100)
    var s2 = spacingParam


    var onImgLoadSocks = () => {
      // draw the top left corner really wide
      ctx.drawImage(img, 0, 0, canvas.width*100, canvas.height*100);

      const imgSizeX = img.width*s
      const imgSizeY = img.height*s

      // case 1: split design in half on side (if numX is one, there's one half on each side and no middle) 
      const numXHalfSides = Math.floor((canvas.width - s2-imgSizeX) / (imgSizeX + s2))+1
      // case 2: margins on both sides
      const numXMarginSides = Math.floor((canvas.width - (imgSizeX + s2)) / (imgSizeX + s2))+1

      // console.log(numXHalfSides, numXMarginSides)
      // console.log(canvas.width, imgSizeX, s2)
      var numX
      var isHalfSplit
      // looks like numXHalfSides is almost always larger
      if (numXMarginSides >= numXHalfSides) {
        // >=, so when equal, prefer margin-sides
        numX = numXMarginSides
        isHalfSplit = false
      } else {
        numX = numXHalfSides
        isHalfSplit = true
      }

      var canStagger = !isHalfSplit || (numX)%2==0 // can't stagger if there's an odd number of columns, because seams won't align

      var spacingX
      var marginX
      if (isHalfSplit) {
        marginX = -(img.width * s) / 2
        spacingX = (canvas.width - (imgSizeX * numX))/(numX)
      } else {
        spacingX = (canvas.width - (numX*imgSizeX)) / (numX)
        marginX = spacingX/2
      }

      var numY = 0
      for (let y=0; y<canvas.height; y+=imgSizeY+spacingX) {
        numY++
      }

      // const marginY = (canvas.height - (numY*imgSizeY) - ((numY-1)*spacingX)) / 2
      const marginY = spacingX
      // console.log("marginY", marginY, "numY", numY)

      for (let i=0; i<numX+1; i++) {
        for (let j=0; j<numY+1; j++) {
          var columnStaggerOffset = 0;
          if (canStagger && staggered && i % 2 == 1) {
              columnStaggerOffset = (imgSizeY + spacingX) / 2;
          }
          let xPos = marginX + i * (imgSizeX + spacingX);
          let yPos = marginY + j * (imgSizeY + spacingX) + columnStaggerOffset;
          if (hasFlips && j % 2 == 1) {
              ctx.scale(-1, 1); // Flip the image
              ctx.translate(-2 * xPos - imgSizeX, 0); // Translate the canvas to the correct position after flipping
          } else {
              ctx.setTransform(1, 0, 0, 1, 0, 0); // Reset the transformations
          }
          ctx.drawImage(img, xPos, yPos, imgSizeX, imgSizeY);
        }
      }
      setCanvasStateNum(c => c+1)
    }

    var onImgLoadHoodie = () => {
      // uses centered, margin, and spacing
      // const s = devicePixelRatio * 1/16 * (10*sizeParam/100)
      const s = 1/16 * (10*sizeParam/100)
      // const imgSizeX = img.width*s*8
      // const imgSizeY = img.height*s*8
      const imgSizeX = img.width*s*14
      const imgSizeY = img.height*s*14
      // const margin = s2

      // get color from img
      ctx.drawImage(img, 0, 0, img.width, img.height);
      var imgBgColor = ctx.getImageData(0, 0, 1, 1).data
      // clear to white with alpha 0
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      // clear to red
      // ctx.fillStyle = "red";
      // ctx.fillRect(0, 0, canvas.width, canvas.height);

      var marginX, marginY
      if (centered) {
        marginX = (canvas.width - imgSizeX) / 2
        marginY = (canvas.height - imgSizeY) / 2
      } else {
        // if (isImg2Img) {
        //   marginY = -((1-0.7)/2)*imgSizeY+10
        // } else {
        marginY = 10
        // }
        // const leftRightPercent=0.84
        const leftRightPercent = 0.84 + (s2 + 5)/100 //s2 is default -5
        marginX = canvas.width*leftRightPercent - imgSizeX/2 // anchor at 85% across, regardless of size
        // marginX = canvas.width - imgSizeX - 10*s2 + -200
        // marginY = 10*s2
      }

      ctx.drawImage(img, marginX, marginY, imgSizeX, imgSizeY);
      setCanvasStateNum(c => c+1)
    }

    if (productType == "socks") {
      img.onload = onImgLoadSocks;
    } else if (productType == "hoodie") {
      img.onload = onImgLoadHoodie;
    }

  }, [processedImageURL, sizeParam, spacingParam, staggered, hasFlips, centered]);


  var displayWidth, displayCropToHeight, aspect, width, height
  if (productType == "socks") {
    const scaleVsPrintify = 1/2
    width = 1348*scaleVsPrintify 
    height = 5595*scaleVsPrintify*1.3
    aspect = width/height
    displayWidth = 200
    displayCropToHeight = 400
  } else if (productType == "hoodie") {
    const scaleVsPrintify = 1.0
    width = 4500*scaleVsPrintify 
    height = 3000*scaleVsPrintify
    aspect = width/height
    displayWidth = 400
    displayCropToHeight = displayWidth/aspect
  } else {
    console.log("unknown product type", productType);
  }

  var out = <>
    <div className="shadow-lg border-[2px] border-gray-500" style={{width: displayWidth, height: displayCropToHeight, overflow: "hidden"}}>
      <canvas ref={canvasRef} width={width} height={height} style={{width: displayWidth, height: displayWidth/aspect}} />
    </div>
    {productType == "hoodie" && <>
      <Canvas camera={{ position: [0, 0, 0.8], fov: 50 }} style={{width: 400, height: 400, touchAction: "pan-y"}} >
        <color attach="background" args={["#DDD"]} />
        <CameraController />
        <ambientLight intensity={1.3}/>
        <pointLight position={[0.0, 1, 0.7]} intensity={0.9} color={"#B5B5B4"} />
        <pointLight position={[0.0, 0.3, 0.7]} intensity={0.9} color={"#B5B5B4"} />
        <ModelPreview canvasRef={canvasRef} canvasStateNum={canvasStateNum} />
      </Canvas>
    </>}
  </>
  return out
}

var Header = ({extra}) => {
  const extraBanner = <>
    <div className="bg-gray-500 w-full p-4 text-white text-md">
      {extra}
    </div>
  </>

  var header = <>
    <div className="bg-black w-full p-4 text-white text-md font-bold">
      <a className="cursor-pointer" href="https://petals.design">
        PETALS
      </a>
    </div>
    {extra && extraBanner}
  </>
  return header
}

var App = () => {
  var out = <>
    <Router>
      <Routes>
        <Route path="/" element={<Selector />} />
        <Route path="/design" element={<Customizer />} />
        <Route path="/success" element={<SuccessPage />} />
      </Routes>
    </Router>
  </>
  return out
}

var SuccessPage = () => {
  var out = <>
    <Header />
    <div className="flex flex-col justify-center items-center gap-y-8 h-[80vh]">
      <FaCircleCheck className="text-8xl text-green-500" />
      <div className="max-w-[400px] text-center">
        Thank you for your order! You will recieve a confirmation when your order ships.
      </div>
      <Button onClick={() => window.location.href = "https://discord.gg/PfAuZUSHK5"} loading={false}>Join the Discord</Button>
      <Button onClick={() => window.location.href = "/"} loading={false}>Back to designer</Button>
    </div>
  </>
  return out
}

var Selector = () => {
  // hoodie, socks, pet hoodie, pet socks
  // placeholder img, description, price
  // --> goes to design page

  var card = (imgURL, name, desc, price, destURL) => {
    var out = (
      <Link to={destURL} className="rounded-md bg-gray-100 hover:shadow-lg overflow-hidden cursor-pointer max-w-[200px] border-1 border-gray-500 border" >
        {/* <img src={imgURL} className="object-cover" crossOrigin='anonymous' draggable={false} /> */}
        {/* square image, 200, object-fit */}
        <div className="relative" style={{width: 200, height: 200}}>
          <div className="absolute inset-0 flex items-center justify-center">
            <img src={imgURL} className="object-cover" crossOrigin='anonymous' draggable={false} />
          </div>
        </div>
        <div className="px-3 py-2">
          <div className="text-lg font-bold">{name}</div>
          <div className="text-sm text-gray-600 pb-1">{desc}</div>
          <div className="text-sm text-gray-600">${price}</div>
        </div>
      </Link>
    );
    return out;
  }

  var out = <>
    <Header extra="Welcome to the designer. Select a product to get started." />
    <div className="flex flex-col justify-center items-center">
      <div className="max-w-lgXX">
        <div className="flex flex-row flex-wrap gap-x-8 gap-y-8 p-8 justify-center w-full">
          {card("./blank-hoodie.jpeg", "Hoodie", "A custom hoodie", 31.99, "/design?product=hoodie")}
          {card("./blank-socks.jpeg", "Socks", "Custom socks", 11.99, "/design?product=socks")}
          {card("./blank-hoodie.jpeg", "Hoodie - Pet", "A custom hoodie, with pet", 31.99, "/design?product=hoodie&pet=true")}
          {card("./blank-socks.jpeg", "Socks - Pet", "Customs socks, with pet.", 11.99, "/design?product=socks&pet=true")}
        </div>
      </div>
    </div>
  </>
  return out
}

var Customizer = () => {

  const [previewImages, setPreviewImages] = useState([{
    url: `/example1.png`,
  }, {
    url: `/example2.png`
  }, {
    url: `/example3.png`
  },{
    url: `/example4.png`,
  }]);
  const [selectedImageIdx, setSelectedImageIdx] = useState(0);
  const [promptText, setPromptText] = useState("");
  const [loadingState, setLoadingState] = useState(null);

  const [printifyProductObj, setPrintifyProductObj] = useState(null);
  const [progressPercent, setProgressPercent] = useState(0);
  const [selectedVariant, setSelectedVariant] = useState(null);

  // const productType = "hoodie"
  // const productType = "socks"
  let [searchParams, setSearchParams] = useSearchParams();
  const productType = searchParams.get("product") || "socks"
  const img2imgOnly = searchParams.get("pet") == "true"

  const [outputScaleInFrame, setOutputScaleInFrame] = useState(1.0); // used for img2img, when image is scaled down before transform

  const canvasRef = useRef(null);

  var previewImgDisplay = ({ url, onClick, selected }) => {
    var out = (
      <div className={`rounded-md w-[80px] h-[80px] bg-gray-200 hover:shadow-lg cursor-pointer overflow-hidden ${selected ? "border-2 border-black" : ""}`} onClick={onClick}>
        <img src={url} className="w-full h-full object-cover" crossOrigin='anonymous' draggable={false} />
      </div>
    );
    return out;
  }


  var genImages = async () =>{
    if (!!loadingState) return;
    if (!img2imgOnly && promptText.length == 0) return;
    setLoadingState("genImages")
    // setPreviewImages([{}, {}, {}, {}]);

    var inputImgBase64 
    if (imageFiles.length > 0) {
      // read file
      var file = imageFiles[0].file
      var file = await imageFiles[0].requestPrepare()
      var reader = new FileReader();
      reader.readAsDataURL(file);
      await new Promise(r => reader.onload = r);
      inputImgBase64 = reader.result//.split(",")[1]
    }
    const doImg2Img = inputImgBase64 != null
    // console.log("Img2Img?", doImg2Img);

    var scaleInFrame
    if (doImg2Img) {
      if (productType == "socks") {
        scaleInFrame = 0.5
      } else if (productType == "hoodie") {
        scaleInFrame = 0.7
      }
    } else {
      scaleInFrame = 1.0
    }
    setOutputScaleInFrame(scaleInFrame)

    var progressFloats = {}
    var updateProgressAvg = () => {
      const progressAvg = Object.values(progressFloats).reduce((a, b) => a + b, 0) / Object.values(progressFloats).length;
      setProgressPercent(Math.floor(progressAvg*100))
    }
    var updateInitialProgressTimeout = null
    setProgressPercent(0)
    var updateInitialProgress = () => {
      setProgressPercent(c => Math.min(c+5, 30))
      updateInitialProgressTimeout = setTimeout(updateInitialProgress, 1.3*1000)
    }
    updateInitialProgress()
    var tasksRes = await fetch(`${API_URL}/generateImages`, {
      method: "POST",
      body: JSON.stringify({ promptText, doImg2Img, inputImgBase64, scaleInFrame }),
      headers: { "Content-Type": "application/json" }
    }).then(res => res.json())
    clearTimeout(updateInitialProgressTimeout)

    var waitForImg = async (idx, task_id) => {
      var hasImage = false
      var imageURLs = []
      var count = 0
      while (!hasImage) {
        var progressRes = await fetch(`${API_URL}/imageProgress`, {
          method: "POST",
          body: JSON.stringify({task_id}),
          headers: {
            "Content-Type": "application/json",
          }
        }).then(res => res.json())

        if (progressRes.code != 0) {
          console.log("error", progressRes);
          break;
        }
        progressFloats[idx] = 0.3+0.65*progressRes.data.progress
        updateProgressAvg()

        // .data.progress is from 0 to 1
        // .data.current_images sometimes has base64 progress images
        if (progressRes.data.imgs?.length > 0) {
          // idk their status codes
          hasImage = true;
          imageURLs = progressRes.data.imgs
          // console.log(progressRes.data)
        }
        // wait 1 second
        await new Promise(r => setTimeout(r, 1000));
      }
      const corsedImageURL = `${API_URL}/fetchImageWithCORS?url=${imageURLs[0]}`
      await preloadImagePromise(corsedImageURL)
      progressFloats[idx] = 1
      updateProgressAvg()
      return corsedImageURL
    }
    var promises = tasksRes.map((res, idx) => waitForImg(idx, res.data.task_id))
    var imageURLs = await Promise.all(promises)
    // setPreviewImages(imageURLs.map(url => ({url})))
    setPreviewImages(curImages => {
      var newImages = [...curImages]
      imageURLs.forEach((url, idx) => {
        newImages.unshift({url})
      })
      return newImages
    })
    setSelectedImageIdx(0);
    setTimeout(() => {
      setLoadingState(null)
    }, 1000);
  }

  const updateProductPreview = async () => {
    setLoadingState("productPreview")
    setPrintifyProductObj(null);
    const canvas = canvasRef.current;
    var imgBase64

    if (productType == "socks") {
      // shift pattern, so that previous "middle" of canvas will be on the side of the sock
      const newCanvas = document.createElement("canvas");
      newCanvas.width = canvas.width
      newCanvas.height = canvas.height
      const ctx = newCanvas.getContext("2d");
      const halfway = Math.floor(canvas.width/2.0)
      ctx.drawImage(canvas, halfway, 0, canvas.width, canvas.height);
      ctx.drawImage(canvas, -halfway, 0, canvas.width, canvas.height);
      imgBase64 = newCanvas.toDataURL("image/png").split(",")[1];
    } else {
      imgBase64 = canvas.toDataURL("image/png").split(",")[1];
    }

    var response = await fetch(`${API_URL}/createProduct`, {
      method: "POST",
      body: JSON.stringify({ imgBase64, productType, promptText }),
      headers: { "Content-Type": "application/json" }
    }).then(res => res.json())

    await preloadImagePromise(response.images[0].src)
    setPrintifyProductObj(response);
    // console.log("createProduct", response);
    setLoadingState(null)
  }

  const continueToCheckout = () => {
    var getCheckoutURL = async () => {
      console.log("printifyProductObj", printifyProductObj);
      setLoadingState("checkout")
      const success_url = `${window.location.origin}/success`
      const cancel_url = window.location.href

      var response = await fetch(`${API_URL}/createPaymentLink`, {
        method: "POST",
        body: JSON.stringify({ printifyProductObj, success_url, cancel_url, variantID: selectedVariant}),
        headers: { "Content-Type": "application/json" }
      }).then(res => res.json())
      setLoadingState(null)
      const shopLink = response.url
      if (!shopLink) {
        alert("Something went wrong while continuing to checkout. Please ask us on Discord for help.")
        return
      }
      console.log("Got stripe link", shopLink);
      return shopLink
    }
    // open in new tab. synchronously, sort of
    var windowRef = window.open("", "_blank")
    getCheckoutURL().then(shopLink => {
      windowRef.location = shopLink
    })
  }

  var [imageFiles, setImageFile] = useState([]);

  const sliderVal1Default = 8
  const sliderVal2Default = -5
  var [sliderVal1, setSliderVal1] = useState(8);
  var [sliderVal2, setSliderVal2] = useState(-5);
  var [isStaggered, setIsStaggered] = useState(true);
  var [isCentered, setIsCentered] = useState(true);
  var [hasFlips, setHasFlips] = useState(true);
  const imageURL = previewImages[selectedImageIdx]?.url

  // useEffect(() => {
  //   setPrintifyProductObj(null);
  // }, [sliderVal1, sliderVal2, isStaggered, isCentered, hasFlips, imageURL, productType])

  const goButton = <>
    <Button onClick={genImages} loading={loadingState=="genImages"} disabled={!!loadingState}>Generate</Button>
  </>

  const numberBox = num => <span className="w-4 h-4 inline-flex items-center justify-center bg-black text-white">{num}</span>


  const generationControls = <>
    <label className="block text-sm font-medium text-gray-700">{numberBox(1)} Generate</label>
    {!img2imgOnly && <>
      <div className="flex flex-row gap-x-2">
        {/* tips */}
        <Input placeholder="enter a prompt, e.g. cute lion!" value={promptText} onChange={(e) => setPromptText(e.target.value)} extraclasses="grow" onKeyDown={(e) => { if (e.key === "Enter") genImages() }} disabled={!!loadingState} />
        {goButton}
      </div>
      {productType == "socks" && <div className="text-gray-500 text-sm">
        Tip: add "seamless texture" to your prompt to get a tiling pattern. Add "white background" to ensure a clean background.
      </div>}
    </>}
    {img2imgOnly && <>
      <FilePond
        disabled={!!loadingState}
        allowFileTypeValidation={true}
        acceptedFileTypes={['image/*']}
        labelFileTypeNotAllowed="Only images are allowed"
        labelIdle='Drag & Drop an image or <span class="filepond--label-action">Browse</span>'
        credits={false} 
        onupdatefiles={setImageFile}
        // imageTransformOutputMimeType="image/png"
      />
      {goButton}
    </>}
    <div className="w-full bg-gray-200 rounded-full">
      <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{width: progressPercent+"%"}}> {progressPercent != 0 ? (progressPercent+"%") : " "}</div>
    </div>
    <div className="flex flex-row overflow-auto gap-4 flex-wrap">
      {previewImages.map((imgInfo, idx) => 
        <React.Fragment key={imgInfo.url}>
          {previewImgDisplay({selected: selectedImageIdx==idx, onClick: () => setSelectedImageIdx(idx), ...imgInfo})}
        </React.Fragment>
      )}
    </div>
    <div>
      <div className="flex flex-row gap-x-2 items-center">
        <label className="block text-sm font-medium text-gray-700">Design Size</label>
        <FaRedoAlt size="18" className="cursor-pointer hover:bg-gray-300 rounded-sm p-1" onClick={() => setSliderVal1(sliderVal1Default)}/>
      </div>
      <Slider  min={5} max={50} value={sliderVal1} onChange={(e) => setSliderVal1(parseInt(e.target.value))} />
    </div>
    <div>
      <div className="flex flex-row gap-x-2 items-center">
        <label className="block text-sm font-medium text-gray-700">Design {productType == "socks" ? "Minimum" : ""} Spacing</label>
        <FaRedoAlt size="18" className="cursor-pointer hover:bg-gray-300 rounded-sm p-1" onClick={() => setSliderVal2(sliderVal2Default)}/>
      </div>
      <Slider  min={-40} max={50} value={sliderVal2} onChange={(e) => setSliderVal2(parseInt(e.target.value))} />
    </div>
    <div className="flex gap-x-4">
      {productType=="socks" && <Checkbox label="Staggered" checked={isStaggered} onChange={(e) => setIsStaggered(e.target.checked)} />}
      {productType=="socks" && <Checkbox label="Flips" checked={hasFlips} onChange={(e) => setHasFlips(e.target.checked)} />}
      {productType=="hoodie" && <Checkbox label="Centered" checked={isCentered} onChange={(e) => setIsCentered(e.target.checked)} />}
    </div>
  </>
  const fullPreviewControls = <>
    <div className="flex flex-col gap-y-1 pt-4">
      <label className="block text-sm font-medium text-gray-700">{numberBox(2)} Full Preview</label>
      <label className="text-sm text-gray-700 pb-1">Create a full preview of your {productType}. Scroll down to view. This may take a minute!</label>
      <Button onClick={updateProductPreview} loading={loadingState =="productPreview"} disabled={!!loadingState}>Create {productType.charAt(0).toUpperCase() + productType.slice(1)} Preview</Button>
    </div>
  </>

  // const chosenVariantIDs = printifyProductObj?.images[0].variant_ids
  // chosen variants is the set of all t he images' variant ids
  // janky
  const chosenVariantIDs = printifyProductObj?.images.map(i => i.variant_ids).flat().filter((v, i, a) => a.indexOf(v) === i)
  const variantSelectOptions = printifyProductObj?.variants.filter(v => chosenVariantIDs.includes(v.id)).map((variant, idx) => ({id: variant.id, label: (productType=="socks" ? 'Size ' : '') + variant.title}))
  // console.log("chosenVariantIDs", chosenVariantIDs, "variantSelectOptions", variantSelectOptions, "selectedVariant", selectedVariant)

  const checkoutControls = <>
    {printifyProductObj && <>
      <div className="flex flex-col gap-y-2 pt-4">
        <label className="block text-sm font-medium text-gray-700">{numberBox(3)} Checkout</label>
        {/* <label className="text-sm text-gray-700 pb-1"></label> */}
        {/* For size selection */}
        <select className="block w-full pl-3 pr-10 py-2 text-base bg-gray-200 focus:outline-none focus:border-indigo-500 sm:text-sm rounded-md border-2" onChange={(e) => setSelectedVariant(e.target.value)}>
          <option value="" disabled selected>Select a size</option>
          {(variantSelectOptions || []).map((v, idx) => <option key={idx} value={v.id}>{v.label}</option>)}
        </select>
        <Button onClick={continueToCheckout} loading={loadingState=="checkout"} disabled={!!loadingState || !selectedVariant}>Continue to Checkout</Button>
      </div>
    </>}
  </>
  const patternPreview = <PatternPreview imageURL={imageURL} sizeParam={sliderVal1} spacingParam={sliderVal2} staggered={isStaggered} hasFlips={hasFlips} centered={isCentered} productType={productType} outputScaleInFrame={outputScaleInFrame} canvasRefIn={canvasRef} />
  const imagePreviewer = <ImagePreviewer imageURLs={printifyProductObj?.images.map(i => i.src) || []} size={400} /> 

  const {isMdAbove}= useIsMdAbove()
  var innerLayout
  if (isMdAbove) {
    innerLayout = <>
      <div className="flex items-start justify-center p-4 md:p-8 gap-4">
        <div className="flex gap-4 flex-wrap width-auto w-min justify-center">
          {patternPreview}
          {imagePreviewer}
        </div>
        <div className="flex flex-col gap-y-4">
          {generationControls}
          {fullPreviewControls}
          {checkoutControls}
        </div>
      </div>
    </>
  } else {
    innerLayout = <>
      <div className="flex flex-col gap-y-4 p-4 md:p-8 pb-8">
        {generationControls}
        {patternPreview}
        {fullPreviewControls}
        {imagePreviewer}
        {checkoutControls}
      </div>
    </>
  }

  return <>
    <Header extra={`Designing: ${productType}` + (img2imgOnly ? " - pets" : "")} />
    {innerLayout}
  </>
}

var ImagePreviewer = ({imageURLs, size}) => {
  const [shownImageIdx, setShownImageIdx] = useState(0);

  var out = <>
    <div>
      <div className="shadow-lg rounded-2xl w-[400px] h-[400px] bg-white">
        {imageURLs.length > 0 && <img src={imageURLs[shownImageIdx]} className="w-full h-full object-cover" />}
        {imageURLs.length == 0 && <div className="w-full h-full flex items-center justify-center">
          <div className="text-gray-500 text-lg">Full Preview Area</div>
        </div>}
      </div>
      <div className="pt-2 gap-x-2 flex flex-row max-w-[400px] overflow-auto">
        {imageURLs.map((url, idx) => <React.Fragment key={url}>
          <div className={`min-w-[60px] min-h-[60px] rounded-md bg-gray-200 hover:shadow-sm cursor-pointer overflow-hidden ${idx == shownImageIdx ? "border border-2 border-black" : "border-2 border-gray-300"}`} onClick={() => setShownImageIdx(idx)}>
            <img src ={url} className="w-full h-full object-cover" />
          </div>
        </React.Fragment>)}
      </div>
    </div>
  </>
  return out
}

const useIsMdAbove = () => {
  const check = () => {
    return window.innerWidth >= 768
  }
  const [isMdAbove, setIsMdAbove] = useState(check());

  useEffect(() => {
    const handleResize = () => {
      setIsMdAbove(check());
    };
    // idk why but if this state changes from false->true, it causes Maximum Update Depth error in the post component
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isOnIosSafari = useMemo(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    // ios 13 fix for isOnIpad, which changed the userAgent
    const isOnIpad = /macintosh/.test( userAgent ) && 'ontouchend' in document;
    const isOnIphone = /iphone|ipad|ipod/.test( userAgent );
    return isOnIphone || isOnIpad;
  }, [])

  const iosPWAInstalled = useMemo(() => {
    return window.navigator.standalone === true;
  })

  return { isMdAbove, isOnIosSafari, iosPWAInstalled };
}

export default App;